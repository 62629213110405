import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { LayoutChangeEvent } from "react-native"
import { ScrollView } from "react-native-gesture-handler"

import styled from "styled-components/native"

import rawTokens from "@treefort/tokens/app"

import { useAppLinkHelpers } from "../hooks/use-app-link-helpers"
import { getAbsoluteLineHeight, getTextStyleObject } from "../lib/text-style"
import LinearGradient from "./linear-gradient"
import Modal from "./modal"
import Spacer from "./spacer"
import Text, { getTextMaxWidthStyle, TextProps } from "./text"
import TextWithLinks from "./text-with-links"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

type BackgroundColor = keyof typeof rawTokens.colors.background

export type TextToggleProps = Omit<TextProps, "children"> & {
  type?: "expander" | "modal"
  title?: string
  onReady?: (ready: boolean) => unknown
  numberOfLines: number
  backgroundColor?: BackgroundColor
} & (
    | { children: React.ReactNode; withLinks?: false }
    | { children: string; withLinks: true }
  )

// This expands the tappable area so that the tiny "Read more" and "See less"
// links are comfortably within the bounds. RN's built-in hit slop feature is
// not used because more often than not the slop is clipped by the parent view.
const HIT_SLOP_STYLE = { padding: 16, margin: -16 }

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

const Container = styled.View<{
  ready: boolean
  maxWidth?: TextProps["maxWidth"]
}>`
  position: relative;
  opacity: ${(props) => (props.ready ? 1 : 0)};
  ${(props) =>
    getTextMaxWidthStyle({
      tokens: props.theme,
      maxWidth: props.maxWidth,
    })};
`

const HiddenTextContainer = styled.View`
  opacity: 0;
  left: 0;
  position: absolute;
  /* Set this to an extreme value to avoid causing extra body scroll in Safari
  when near the bottom of the page */
  top: -9999%;
`

const ReadMoreContainer = styled.View`
  position: absolute;
  bottom: ${HIT_SLOP_STYLE.padding}px;
  right: ${HIT_SLOP_STYLE.padding}px;
  flex-direction: row;
  align-items: flex-end;
`

const ReadMoreGradient = styled(LinearGradient)<{
  textStyle: TextProps["textStyle"]
}>`
  width: 48px;
  height: ${(props) => getAbsoluteLineHeight(props.textStyle, props.theme)}px;
`

const ReadMoreText = styled(Text)<{ backgroundColor: BackgroundColor }>`
  padding-left: ${(props) => props.theme.spacing.xsmall}px;
  background-color: ${(props) =>
    props.theme.colors.background[props.backgroundColor]};
`

/**
 * This component displays a specified number of lines of text along with a read
 * more button. This read more button is not displayed if the entire text fits
 * within the number of collapsed lines.
 *
 * There is a callback that is called once the component has calculated if the
 * text needs truncated. This is useful if you want other components on a page
 * to show their loading states until this component is done loading. The pages
 * just look a little better if everything loads together, and it's just one
 * render cycle so it isn't a big deal to wait for it.
 */
function TextToggle({
  children,
  numberOfLines,
  type = "expander",
  title,
  onReady,
  backgroundColor = "primary",
  withLinks,
  ...textProps
}: TextToggleProps): JSX.Element | null {
  const cancelled = useRef<boolean>()
  const { tokens } = useTokens()
  const [expanded, setExpanded] = useState(false)
  const [textIsTruncated, setTextIsTruncated] = useState<boolean>()
  const ready = textIsTruncated !== undefined
  const toggleText = useCallback(() => {
    setExpanded((expanded) => !expanded)
  }, [])
  const appLinkHelpers = useAppLinkHelpers()
  const { t } = useTranslation()

  const onLayoutHiddenText = useCallback(
    (event: LayoutChangeEvent) => {
      if (!cancelled.current) {
        const { lineHeight } = getTextStyleObject(textProps.textStyle, tokens)
        const renderedLines = Math.floor(
          // On iOS layout.height can change from a whole number to a decimal
          // across renders, changing the value of renderedLines and causing an
          // infinite flicker effect if it's not rounded
          Math.round(event.nativeEvent.layout.height) / lineHeight,
        )
        setTextIsTruncated(renderedLines > numberOfLines)
      }
    },
    [textProps.textStyle, tokens, numberOfLines],
  )

  const expandedText = withLinks ? (
    <TextWithLinks {...textProps} getAppLinkProps={appLinkHelpers.getProps}>
      {children}
    </TextWithLinks>
  ) : (
    <Text {...textProps}>{children}</Text>
  )

  const collapsedText = withLinks ? (
    <TextWithLinks
      {...textProps}
      getAppLinkProps={appLinkHelpers.getProps}
      numberOfLines={numberOfLines}
    >
      {children}
    </TextWithLinks>
  ) : (
    <Text {...textProps} numberOfLines={numberOfLines}>
      {children}
    </Text>
  )

  useEffect(() => {
    if (onReady) {
      onReady(ready)
    }
  }, [ready, onReady])

  useEffect(
    () => () => {
      cancelled.current = true
    },
    [],
  )

  return children ? (
    <Container ready={ready} maxWidth={textProps.maxWidth}>
      <HiddenTextContainer
        pointerEvents="none"
        aria-hidden
        onLayout={onLayoutHiddenText}
      >
        {/*
        This text is used to determine the number of lines the component will
        take up to see whether or not it needs truncated.
        */}
        {expandedText}
      </HiddenTextContainer>
      {
        // you can press the text to expand but not to collapse. It also doesn't
        // need to be pressable if it doesn't need truncated
        textIsTruncated && (!expanded || type === "modal") ? (
          <Touchable onPress={toggleText} style={HIT_SLOP_STYLE}>
            {collapsedText}
            <ReadMoreContainer>
              <ReadMoreGradient
                fadeTowards="left"
                textStyle={textProps.textStyle}
                color={tokens.colors.background[backgroundColor]}
              />
              <ReadMoreText
                textStyle="strong"
                color="highContrast"
                backgroundColor={backgroundColor}
              >
                {t("Read more")}
              </ReadMoreText>
            </ReadMoreContainer>
          </Touchable>
        ) : textIsTruncated && expanded ? (
          <>
            {expandedText}
            <Spacer size="xsmall" />
            <Touchable onPress={toggleText} style={HIT_SLOP_STYLE}>
              <Text textStyle="strong" color="highContrast">
                {t("Show less")}
              </Text>
            </Touchable>
          </>
        ) : (
          expandedText
        )
      }
      {type === "modal" ? (
        <TextToggleModal
          title={title}
          open={expanded}
          onPressOutside={toggleText}
          onPressCloseButton={toggleText}
        >
          {expandedText}
        </TextToggleModal>
      ) : null}
    </Container>
  ) : null
}

export function TextToggleModal(
  props: Parameters<typeof Modal>[0] & { children: React.ReactNode },
) {
  const { tokens } = useTokens()
  return (
    <Modal {...props} maxWidth={460}>
      <ScrollView
        dataSet={WEB_SCROLL_UNLOCK}
        contentContainerStyle={{ padding: tokens.spacing.medium }}
      >
        {props.children}
      </ScrollView>
    </Modal>
  )
}

export default TextToggle
