"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSearchResultMetadata = exports.getObjectID = void 0;
const contributor_1 = require("./contributor");
/**
 * Returns a correctly formatted objectID, given a type and an id
 */
const getObjectID = (type, id) => `${type}-${id}`;
exports.getObjectID = getObjectID;
/**
 * Takes a search index object and extracts normalized metadata object useful
 * for rendering a search result.
 */
function getSearchResultMetadata({ item, artworkInputs, }) {
    const contentArtwork = "artworkMedia" in item.details
        ? item.details.artworkMedia
            ? [item.details.artworkMedia]
            : item.details.artwork
                ? [
                    {
                        type: "url",
                        id: item.details.artwork,
                        url: item.details.artwork,
                    },
                ]
                : []
        : [];
    switch (item.objectType) {
        case "book":
        case "ebook":
            return {
                subtitle: (0, contributor_1.joinContributorNames)(item.contributors, "author"),
                artworkMedia: contentArtwork,
            };
        case "podcast":
            return {
                subtitle: (0, contributor_1.joinContributorNames)(item.contributors, "host"),
                artworkMedia: contentArtwork,
            };
        case "video":
            return {
                subtitle: item.details.seriesTitle,
                artworkMedia: contentArtwork,
            };
        case "album":
            return {
                subtitle: (0, contributor_1.joinContributorNames)(item.contributors, "artist"),
                artworkMedia: contentArtwork,
            };
        case "videoSeries":
        case "webEmbed":
            return {
                subtitle: undefined,
                artworkMedia: contentArtwork,
            };
        case "page": {
            return {
                subtitle: undefined,
                artworkMedia: (artworkInputs === null || artworkInputs === void 0 ? void 0 : artworkInputs.pageArtworkMedia)
                    ? [artworkInputs.pageArtworkMedia]
                    : [],
            };
        }
        case "collection": {
            return {
                subtitle: undefined,
                artworkMedia: (artworkInputs === null || artworkInputs === void 0 ? void 0 : artworkInputs.collection)
                    ? artworkInputs.collection.content.flatMap((content) => content.artworkMedia ? content.artworkMedia : [])
                    : [],
            };
        }
        case "albumTrack": {
            return {
                subtitle: item.details.albumTitle,
                artworkMedia: item.details.albumArtwork
                    ? [item.details.albumArtwork]
                    : [],
            };
        }
        default:
            return { subtitle: undefined, artworkMedia: [] };
    }
}
exports.getSearchResultMetadata = getSearchResultMetadata;
