import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import treefortLogo from "@treefort/tokens/app/images/treefort-logo"

import config from "../config"
import { useShowBranding } from "../hooks/use-show-branding"
import { AppLink } from "./app-link"
import Image from "./image"
import Row from "./row"
import Text from "./text"
import { useTokens } from "./tokens-provider"

const TreefortWordmark = styled(Image)`
  height: 16px;
  width: 76px;
  bottom: 2px;
`

/**
 * Returns null if the branding visibility enum is "off" or "never"
 */
export function PoweredByTreefort() {
  const { tokens } = useTokens()
  const { t } = useTranslation()
  const showBranding = useShowBranding()
  return showBranding ? (
    <AppLink
      to={{ type: "url", url: config.TREEFORT_SITE_URL }}
      aria-label={t("Powered by {{providerName}}", {
        providerName: "Treefort",
      })}
    >
      <Row justifyContent="center">
        <Text textStyle="headingXSmall" color="secondary">
          {t("Powered by {{providerName}}", { providerName: " " })}
        </Text>
        <TreefortWordmark
          source={treefortLogo}
          resizeMode="contain"
          tintColor={tokens.colors.text.secondary}
        />
      </Row>
    </AppLink>
  ) : null
}
