"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsPlugin = exports.AnalyticsLogger = exports.Parameter = exports.EventName = void 0;
// IMPORTANT: Read this doc before adding a new event here:
// https://www.notion.so/Working-with-ClickHouse-175d66b42a8d80ad89a0e2a00f2f1923?pvs=4#175d66b42a8d80b8873afaffaa8ae254
var EventName;
(function (EventName) {
    EventName["CheckoutComplete"] = "tf_checkout_complete";
    EventName["CheckoutStart"] = "tf_checkout_start";
    EventName["Contact"] = "tf_contact";
    EventName["PlayTimeMilestone"] = "tf_play_time_milestone";
    EventName["PlayPositionMilestone"] = "tf_play_position_milestone";
    EventName["PlayProgress"] = "tf_play_progress";
    EventName["PlayRequest"] = "tf_play_request";
    EventName["ReadPositionMilestone"] = "tf_read_position_milestone";
    EventName["ReadProgress"] = "tf_read_progress";
    EventName["ReadRequest"] = "tf_read_request";
    EventName["ScreenView"] = "tf_screen_view";
    EventName["Search"] = "tf_search";
    EventName["Share"] = "tf_share";
    EventName["SubscriptionActivated"] = "tf_subscription_activated";
    EventName["SubscriptionRenewed"] = "tf_subscription_renewed";
    EventName["SubscriptionCanceled"] = "tf_subscription_canceled";
    EventName["SubscriptionUncanceled"] = "tf_subscription_uncanceled";
    EventName["SubscriptionTransferred"] = "tf_subscription_transferred";
    EventName["SubscriptionPlanChanged"] = "tf_subscription_planChanged";
    EventName["SubscriptionDeactivated"] = "tf_subscription_deactivated";
    EventName["UserRegistered"] = "tf_user_registered";
    EventName["UserAuthenticated"] = "tf_user_authenticated";
})(EventName = exports.EventName || (exports.EventName = {}));
/**
 * EVENT PARAMETERS
 *
 * NOTE: Parameters that hold IDs are typed as strings here even though we
 * usually store IDs as numbers in our database. This is primarily for
 * compatibility with GA (in the past there were some now-forgotten issues with
 * firebase native and number IDs). IDs are generally treated more like strings
 * from a practical standpoint anyway (e.g. when filtering for an ID you would
 * likely never use a number-style GREATER_THAN or LESS_THAN match).
 */
var Parameter;
(function (Parameter) {
    Parameter["AppVersion"] = "tf_app_version";
    Parameter["CheckoutCode"] = "tf_checkout_code";
    Parameter["CheckoutType"] = "tf_checkout_type";
    Parameter["ClientId"] = "tf_client_id";
    Parameter["CollectionId"] = "tf_collection_id";
    Parameter["CollectionTitle"] = "tf_collection_title";
    Parameter["ContentId"] = "tf_content_id";
    Parameter["ContentPodcastEpisode"] = "tf_content_podcast_episode";
    Parameter["ContentSku"] = "tf_content_sku";
    Parameter["ContentTitle"] = "tf_content_title";
    Parameter["ContentType"] = "tf_content_type";
    Parameter["Currency"] = "tf_currency";
    Parameter["Duration"] = "tf_duration";
    Parameter["EngagementTime"] = "tf_engagement_time";
    Parameter["FromUserId"] = "tf_from_user_id";
    Parameter["MilestonePercent"] = "tf_milestone_percent";
    Parameter["Platform"] = "tf_platform";
    Parameter["PlaybackRate"] = "tf_playback_rate";
    Parameter["PlayMode"] = "tf_play_mode";
    Parameter["PlayPosition"] = "tf_play_position";
    Parameter["PlayTime"] = "tf_play_time";
    Parameter["PlayTrack"] = "tf_play_track";
    Parameter["PreviousSubscriptionPlanId"] = "tf_prev_subscription_plan_id";
    Parameter["Price"] = "tf_price";
    Parameter["ProfileId"] = "tf_profile_id";
    Parameter["ProgressPercent"] = "tf_progress_percent";
    Parameter["RecommId"] = "tf_recomm_id";
    Parameter["ScreenPageId"] = "tf_screen_page_id";
    Parameter["ScreenPath"] = "tf_screen_path";
    Parameter["ScreenTabId"] = "tf_screen_tab_id";
    Parameter["ScreenTitle"] = "tf_screen_title";
    Parameter["SearchOutcome"] = "tf_search_outcome";
    Parameter["SearchQuery"] = "tf_search_query";
    Parameter["SearchResultId"] = "tf_search_result_id";
    Parameter["SearchResultsCount"] = "tf_search_results_count";
    Parameter["SearchResultType"] = "tf_search_result_type";
    Parameter["Subscribed"] = "tf_subscribed";
    Parameter["SubscriptionFreeTrial"] = "tf_subscription_free_trial";
    Parameter["SubscriptionId"] = "tf_subscription_id";
    Parameter["SubscriptionPlanId"] = "tf_subscription_plan_id";
    Parameter["SubscriptionPlanProvider"] = "tf_subscription_plan_provider";
    Parameter["TenantId"] = "tf_tenant_id";
    Parameter["TransactionId"] = "tf_transaction_id";
    Parameter["UserAuthenticated"] = "tf_user_authenticated";
    Parameter["UserId"] = "tf_user_id";
    Parameter["WordsVisible"] = "tf_words_visible";
})(Parameter = exports.Parameter || (exports.Parameter = {}));
class AnalyticsLogger {
    constructor({ plugins, logError, logEvent, }) {
        this.logError = (description) => (cause) => this.customLogError(new Error(`[Analytics] ${description}`, { cause }));
        /**
         * Identify the current user.
         */
        this.identifyUser = async (user) => {
            if (!this.enabled)
                return;
            await Promise.all(this.plugins.map((plugin) => { var _a; return (_a = plugin.identifyUser) === null || _a === void 0 ? void 0 : _a.call(plugin, user); })).catch(this.logError("Failed to set a parameter"));
        };
        /**
         * Log that an event occurred. As with all events, it's up to a plugin to
         * handle anything passed here.
         */
        this.logEvent = async (event, context) => {
            var _a;
            if (!this.enabled)
                return;
            (_a = this.customLogEvent) === null || _a === void 0 ? void 0 : _a.call(this, event);
            await Promise.all(this.plugins.map((plugin) => plugin.logEvent(event, context))).catch(this.logError("Failed to log event"));
        };
        /**
         * Enable or disable the logging of analytics events
         */
        this.setEnabled = async (enabled) => {
            this.enabled = enabled;
            await Promise.all(this.plugins.map((plugin) => { var _a; return (_a = plugin.setEnabled) === null || _a === void 0 ? void 0 : _a.call(plugin, enabled); })).catch(this.logError("Failed to toggle enabled state"));
        };
        this.enabled = true;
        this.plugins = plugins;
        this.customLogError = logError;
        this.customLogEvent = logEvent;
    }
}
exports.AnalyticsLogger = AnalyticsLogger;
class AnalyticsPlugin {
}
exports.AnalyticsPlugin = AnalyticsPlugin;
