import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { ScrollView } from "react-native-gesture-handler"

import debounce from "lodash/debounce"
import { RecyclerListViewProps } from "recyclerlistview"
import styled from "styled-components/native"

import {
  AppLink as AppLinkType,
  AppSliderItems,
  ContentType,
  DateDisplayStrategy,
} from "@treefort/api-spec"

import useCollection from "../../hooks/use-collection"
import useContent from "../../hooks/use-content"
import { useRecommendations } from "../../hooks/use-recommendations"
import { getConsumableContentFromContentResponse } from "../../lib/consumable-content"
import {
  shouldShowLockIcon,
  shouldShowProgressForContent,
} from "../../lib/content"
import { formatDate } from "../../lib/date"
import { isSyntheticEvent } from "../../lib/is-synthetic-event"
import { debug as appDebug } from "../../lib/logging"
import { getAbsoluteLineHeight } from "../../lib/text-style"
import { AppLink } from "../app-link"
import ArtworkListItem, {
  ArtworkShape,
  TEXT_STYLE_SUBTITLE,
  TEXT_GAP_VERTICAL,
  TEXT_MARGIN_VERTICAL,
  TEXT_STYLE_TITLE,
} from "../artwork-list-item"
import Box, { BoxPadding } from "../box"
import { Heading } from "../heading"
import HorizontalListViewWithArrows from "../horizontal-list-view-with-arrows"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Spacer from "../spacer"
import Text from "../text"
import { ResolvedTokens, useTokens } from "../tokens-provider"

const debug = appDebug.extend("slider")

type Size = "medium" | "large"
type Layout = "1-row" | "2-row" | "mixed"

const MAX_DYNAMIC_ITEM_COUNT = {
  "1-row": 10,
  "2-row": 21,
  mixed: 24,
}
const SINGLE_LINE_TITLE_BREAKPOINT = 240
const DEFAULT_SIZE = "medium"
const DEFAULT_LAYOUT = "1-row"
const DEFAULT_ITEM_SHAPE = "square"
const ACTION_LINK_TEXT_STYLE = "body"

export type SliderProps = {
  items: Items
  title?: string | null
  actionLink?: { title: string; link: AppLinkType }
  size?: Size
  showItemTitles?: boolean
  showItemDates?: DateDisplayStrategy
  layout?: Layout
  itemShape?: ArtworkShape
  paddingTop?: BoxPadding
  // Set this and the slider's scroll will be restored after being remounted (as
  // long as the key stays the same). The scroll position is remembered until
  // the app is rebooted.
  scrollRestorationKey?: string
}

type Items =
  | {
      type: "custom"
      items: SliderItem[] | []
    }
  | { type: "collection"; collectionId: number }
  | { type: "podcast"; contentId: number }
  | { type: "videoSeries"; contentId: number }
  | {
      type: "recombee"
      recommendation: Extract<
        AppSliderItems,
        { type: "recombee" }
      >["recommendation"]
    }

type SliderItem = {
  title: string
  subtitle?: string
  link?: AppLinkType
  imageUrl?: string
  showProgressBar?: boolean
  showLockIcon?: boolean
  linkParams?: Record<string, string>
}

type ColumnItem =
  | (SliderItem & { width: number; artworkHeight: number; textHeight: number })
  | (Partial<Record<keyof SliderItem, never>> & {
      width: number
      artworkHeight: number
      textHeight: number
    })

type Column = [ColumnItem] | [ColumnItem, ColumnItem]

const scrollOffsets = new Map()

export const getSliderDimensions = ({
  tokens,
  title,
  actionLink,
  preferredLayout = DEFAULT_LAYOUT,
  size = DEFAULT_SIZE,
  itemShape = DEFAULT_ITEM_SHAPE,
  preferredShowItemTitles,
  preferredShowItemDates,
}: {
  tokens: ResolvedTokens
  title?: SliderProps["title"]
  actionLink?: SliderProps["actionLink"]
  preferredLayout?: SliderProps["layout"]
  size?: SliderProps["size"]
  preferredShowItemTitles?: SliderProps["showItemTitles"]
  preferredShowItemDates?: SliderProps["showItemDates"]
  itemShape?: SliderProps["itemShape"]
}) => {
  // Determine final options based on preferences
  const layout =
    itemShape !== "tall" &&
    itemShape !== "square" &&
    preferredLayout === "mixed"
      ? "1-row"
      : preferredLayout || DEFAULT_LAYOUT
  const showItemItemTitles =
    Boolean(preferredShowItemTitles) && layout !== "mixed"
  const showItemDates =
    preferredShowItemDates !== undefined &&
    preferredShowItemDates !== "hidden" &&
    layout !== "mixed"

  // Calculate artwork dimensions
  const minimumArtworkHeight =
    layout === "mixed"
      ? tokens.slider.item.shape[itemShape as "square" | "tall"].height[
          "mixedBase"
        ][size]
      : tokens.slider.item.shape[itemShape].height[layout][size]
  const minimumArtworkWidth =
    minimumArtworkHeight * tokens.artworkListItem.shape[itemShape].aspectRatio

  // Calculate text dimensions
  const itemTitleNumberOfLines = !showItemItemTitles
    ? 0
    : minimumArtworkWidth < SINGLE_LINE_TITLE_BREAKPOINT
      ? 2
      : 1
  const itemTitleHeight = showItemItemTitles
    ? getAbsoluteLineHeight(TEXT_STYLE_TITLE, tokens) * itemTitleNumberOfLines
    : 0
  const itemSubtitleNumberOfLines = !showItemDates ? 0 : 1
  const itemSubtitleHeight = showItemDates
    ? getAbsoluteLineHeight(TEXT_STYLE_SUBTITLE, tokens) *
      itemSubtitleNumberOfLines
    : 0
  const itemTextHeight =
    itemTitleHeight +
    itemSubtitleHeight +
    (itemTitleHeight && itemSubtitleHeight ? TEXT_GAP_VERTICAL : 0) +
    (itemTitleHeight || itemSubtitleHeight ? TEXT_MARGIN_VERTICAL * 2 : 0)
  const sliderTitleRowHeight = title
    ? getAbsoluteLineHeight(tokens.slider.title.textStyle, tokens) +
      tokens.slider.title.paddingBottom
    : actionLink
      ? getAbsoluteLineHeight(ACTION_LINK_TEXT_STYLE, tokens) +
        tokens.slider.title.paddingBottom
      : 0

  // Calculate total height
  const itemsHeight =
    (minimumArtworkHeight + itemTextHeight) * (layout === "1-row" ? 1 : 2) +
    (layout === "1-row" ? 0 : tokens.artworkListItem.spacing)
  const height = sliderTitleRowHeight + itemsHeight
  const arrowButtonHeight = itemsHeight - itemTextHeight

  return {
    layout,
    height,
    itemsHeight,
    itemTitleNumberOfLines,
    itemSubtitleNumberOfLines,
    itemTextHeight,
    minimumArtworkHeight,
    arrowButtonHeight,
  }
}

const SliderHeading = styled(Heading)`
  flex-shrink: 1;
`

export function SliderModule({
  items,
  title,
  actionLink,
  paddingTop,
  scrollRestorationKey,
  size = DEFAULT_SIZE,
  layout: preferredLayout = DEFAULT_LAYOUT,
  itemShape = DEFAULT_ITEM_SHAPE,
  showItemTitles: preferredShowItemTitles,
  showItemDates: preferredShowItemDates,
}: SliderProps): JSX.Element {
  const { i18n } = useTranslation()
  const { tokens, displayWidth: width } = useTokens()
  const {
    layout,
    itemsHeight,
    itemTextHeight,
    itemTitleNumberOfLines,
    itemSubtitleNumberOfLines,
    minimumArtworkHeight,
    arrowButtonHeight,
  } = getSliderDimensions({
    tokens,
    title,
    actionLink,
    preferredLayout,
    size,
    itemShape,
    preferredShowItemTitles,
    preferredShowItemDates,
  })

  const videoSeriesQuery = useContent(
    items.type === "videoSeries" ? items.contentId : 0,
    "videoSeries",
    { enabled: items.type === "videoSeries" },
  )

  const podcastQuery = useContent(
    items.type === "podcast" ? items.contentId : 0,
    "podcast",
    { enabled: items.type === "podcast" },
  )

  const collectionQuery = useCollection(
    items.type === "collection" ? items.collectionId : 0,
    { enabled: items.type === "collection" },
  )

  const recommendationsQuery = useRecommendations({
    contentId:
      items.type === "recombee" && items.recommendation.type === "itemsToItem"
        ? items.recommendation.contentId
        : undefined,
    scenario:
      items.type === "recombee" ? items.recommendation.scenario : undefined,
    count: Math.max(...Object.values(MAX_DYNAMIC_ITEM_COUNT)),
    enabled: items.type === "recombee",
    personalized:
      items.type == "recombee" && items.recommendation.type === "itemsToItem"
        ? items.recommendation.personalized
        : undefined,
  })

  const sliderItems = useMemo(() => {
    switch (items.type) {
      case "custom":
        return items.items

      case "collection":
        return collectionQuery.data
          ? collectionQuery.data.content
              .slice(0, MAX_DYNAMIC_ITEM_COUNT[layout])
              .map((item): SliderItem => {
                return {
                  link: {
                    contentType: item.type,
                    type: "content",
                    contentId: item.id,
                  },
                  title: item.title,
                  subtitle: item.publishedAt
                    ? formatDate(new Date(item.publishedAt), {
                        strategy: preferredShowItemDates,
                        i18n,
                      })
                    : undefined,
                  imageUrl: item.artworkMedia?.original.url,
                  showProgressBar: item.availability.status === "available",
                  showLockIcon: shouldShowLockIcon(item.availability),
                }
              })
          : []

      case "podcast":
        return podcastQuery.data
          ? podcastQuery.data.details.episodes
              .slice(0, MAX_DYNAMIC_ITEM_COUNT[layout])
              .map(
                ({ title, artworkMedia, episode, date }): SliderItem => ({
                  imageUrl: artworkMedia?.url,
                  title,
                  subtitle: date
                    ? formatDate(new Date(date), {
                        strategy: preferredShowItemDates,
                        i18n,
                      })
                    : undefined,
                  link: {
                    episodeNumber: episode,
                    type: "podcastEpisode",
                    podcastId: podcastQuery.data.id,
                  },
                  showProgressBar:
                    podcastQuery.data.availability.status === "available",
                  showLockIcon: shouldShowLockIcon(
                    podcastQuery.data.availability,
                  ),
                }),
              )
          : []

      case "videoSeries": {
        if (videoSeriesQuery.data) {
          const seasons = videoSeriesQuery.data.details.seasons
          return [...seasons]
            .reverse()
            .flatMap((season) => season.episodes)
            .slice(0, MAX_DYNAMIC_ITEM_COUNT[layout])
            .map((item) => ({
              title: item.title,
              subtitle: item.publishedAt
                ? formatDate(new Date(item.publishedAt), {
                    strategy: preferredShowItemDates,
                    i18n,
                  })
                : undefined,
              imageUrl: item.artworkMedia?.original.url,
              link: {
                contentType: "video",
                type: "content",
                contentId: item.id,
              },
              showProgressBar: item.availability.status === "available",
              showLockIcon: shouldShowLockIcon(item.availability),
            }))
        } else {
          return []
        }
      }

      case "recombee": {
        if (recommendationsQuery.data) {
          return recommendationsQuery.data.content
            .slice(0, MAX_DYNAMIC_ITEM_COUNT[layout])
            .map((item) => ({
              link: {
                contentType: item.type,
                type: "content",
                contentId: item.id,
              },
              title: item.title,
              subtitle: item.publishedAt
                ? formatDate(new Date(item.publishedAt), {
                    strategy: preferredShowItemDates,
                    i18n,
                  })
                : undefined,
              imageUrl: item.artworkMedia?.original.url,
              showProgressBar: item.availability.status === "available",
              showLockIcon: shouldShowLockIcon(item.availability),
              linkParams: recommendationsQuery.data.recommId
                ? { recommId: recommendationsQuery.data.recommId }
                : undefined,
            }))
        } else {
          return []
        }
      }

      default:
        return []
    }
  }, [
    items,
    collectionQuery.data,
    layout,
    podcastQuery.data,
    videoSeriesQuery.data,
    recommendationsQuery.data,
    preferredShowItemDates,
    i18n,
  ])

  const columns: Column[] = (
    sliderItems.length
      ? sliderItems
      : new Array(MAX_DYNAMIC_ITEM_COUNT[layout]).fill(null)
  ).reduce((columns, item, i): Column[] => {
    const aspectRatio = tokens.artworkListItem.shape[itemShape].aspectRatio
    const baseWidth = Math.round(minimumArtworkHeight * aspectRatio)
    const uniformItem = {
      ...item,
      width: baseWidth,
      artworkHeight: minimumArtworkHeight,
      textHeight: itemTextHeight,
    } as ColumnItem
    if (layout === "1-row") {
      return [...columns, [uniformItem]]
    } else if (layout === "2-row") {
      if (i % 2 === 0) {
        // Start a column on even indices.
        return [...columns, [uniformItem]]
      } else {
        // On odd indices finish the column started on the previous iteration.
        columns[columns.length - 1].push(uniformItem)
        return columns
      }
    } else {
      // layout is "mixed" at this point (1 item spanning 2 rows followed by a 2 x 2 grid of items)
      if (i % 5 === 0) {
        // Add a 2 row column every 5th index.
        const artworkHeight =
          minimumArtworkHeight * 2 + tokens.artworkListItem.spacing
        return [
          ...columns,
          [
            {
              ...item,
              width: Math.round(artworkHeight * aspectRatio),
              artworkHeight,
              textHeight: itemTextHeight,
            },
          ],
        ]
      } else if ((i % 5) % 2 === 1) {
        // Start a new column on the 1st and 3rd indices after every 5th index
        return [...columns, [uniformItem]]
      } else {
        // Finish the column started on the previous iteration.
        columns[columns.length - 1].push(uniformItem)
        return columns
      }
    }
  }, [] as Column[])

  const getKeyForColumn = useCallback((column: Column, index: number) => {
    const titles = column.map((item) => item?.title)
    return `${
      titles.length
        ? `column-${index}-of-${titles.join("-")}`
        : `placeholder-column-${index}`
    }`
  }, [])

  // Save the slider's scroll position so that we can restore it later. This is
  // debounced at a slower rate than the scroll events are actually fired, so
  // practically speaking this is equivalent to onScrollEnd.
  const onScrollDebounced = useMemo(
    () =>
      debounce(
        (event) => {
          if (scrollRestorationKey) {
            scrollOffsets.set(
              scrollRestorationKey,
              event.nativeEvent.contentOffset.x,
            )
          }
        },
        100,
        { leading: false, trailing: true },
      ),
    [scrollRestorationKey],
  )

  const onScroll = useCallback<NonNullable<RecyclerListViewProps["onScroll"]>>(
    (event) => {
      if (isSyntheticEvent(event)) event.persist()
      onScrollDebounced(event)
    },
    [onScrollDebounced],
  )

  const containerStyle = useMemo(
    () => ({
      paddingLeft: tokens.spacing.pagePaddingHorizontal,
      // ArtworkListItems have baked in right padding so we need to subtract
      // that from the page padding to get the same right padding as we have
      // left padding
      paddingRight:
        tokens.spacing.pagePaddingHorizontal - tokens.artworkListItem.spacing,
    }),
    [tokens],
  )

  return (
    <Box paddingTop={paddingTop}>
      {title || actionLink ? (
        <Row
          alignItems="flex-end"
          justifyContent={title ? "space-between" : "flex-end"}
          paddingHorizontal="pagePaddingHorizontal"
          paddingBottom={tokens.slider.title.paddingBottom}
        >
          {title ? (
            <SliderHeading
              level={4}
              textStyle={tokens.slider.title.textStyle}
              numberOfLines={1}
            >
              {title}
            </SliderHeading>
          ) : null}
          {actionLink ? (
            <>
              <Spacer size="medium" horizontal />
              <AppLink
                to={actionLink.link}
                feedback="opacity"
                hitSlop={{ top: 16, bottom: 16, left: 0, right: 0 }}
                aria-label={actionLink.title}
              >
                <Text textStyle={ACTION_LINK_TEXT_STYLE}>
                  {actionLink.title}
                </Text>
              </AppLink>
            </>
          ) : null}
        </Row>
      ) : null}
      <>
        {columns.some((column) => column[0].title) ? (
          <HorizontalListViewWithArrows
            arrowButtonHeight={arrowButtonHeight}
            showsVerticalScrollIndicator={false}
            items={columns}
            key={
              // Key by item height so that the list view is remounted if the
              // height changes. This ensures that the slider will re-render
              // correctly if the height changes on-the-fly in preview mode.
              itemsHeight
            }
            viewSize={{ width, height: itemsHeight }}
            initialOffset={
              scrollRestorationKey
                ? scrollOffsets.get(scrollRestorationKey)
                : undefined
            }
            getItemSize={(item) => item[0].width}
            getItemKey={getKeyForColumn}
            getGapSize={() => tokens.artworkListItem.spacing}
            paddingStart={tokens.spacing.pagePaddingHorizontal}
            paddingEnd={tokens.spacing.pagePaddingHorizontal}
            scrollEventThrottle={8}
            onScroll={scrollRestorationKey ? onScroll : undefined}
            renderItem={(item, index) => (
              <SliderColumn
                itemTitleNumberOfLines={itemTitleNumberOfLines}
                itemSubtitleNumberOfLines={itemSubtitleNumberOfLines}
                column={item}
                index={index}
                itemShape={itemShape}
              />
            )}
            renderAheadOffset={0}
          />
        ) : (
          // Use a ScrollView for loading states (the added performance cost of
          // a ListView is not worth it for a few loading items)
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            bounces={false}
            style={containerStyle}
          >
            {columns.map((column, index) => (
              <SliderColumn
                key={getKeyForColumn(column, index)}
                itemTitleNumberOfLines={itemTitleNumberOfLines}
                itemSubtitleNumberOfLines={itemSubtitleNumberOfLines}
                column={column}
                index={index}
                itemShape={itemShape}
              />
            ))}
          </ScrollView>
        )}
      </>
    </Box>
  )
}

const ProgressWrapper = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
`

function SliderItemProgressBar({
  contentLink,
}: {
  contentLink: AppLinkType | undefined
}) {
  let contentId: number | undefined
  let contentType: ContentType | undefined
  let podcastEpisodeNumber: number | undefined

  switch (contentLink?.type) {
    case "content":
      if (shouldShowProgressForContent(contentLink.contentType)) {
        contentId = contentLink.contentId
        contentType = contentLink.contentType
      }

      break
    case "podcastEpisode":
      contentId = contentLink.podcastId
      podcastEpisodeNumber = contentLink.episodeNumber
      contentType = "podcast"

      break
  }

  const content = useContent(contentId, contentType, {
    enabled: Boolean(contentId && contentType),
  })

  if (!content.data) {
    debug(`[${contentType}:${contentId}] No content data; returning null`)
    return null
  }

  return (
    <ProgressWrapper>
      <ProgressForConsumableContent
        consumableContent={getConsumableContentFromContentResponse({
          content: content.data,
          podcastEpisodeNumber,
        })}
        includeProgressBar={true}
        includeFinishedProgressBar={true}
      />
    </ProgressWrapper>
  )
}

function SliderItem({
  column,
  columnIndex,
  itemTitleNumberOfLines,
  itemSubtitleNumberOfLines,
  itemShape,
  item,
}: {
  column: Column
  columnIndex: number
  itemTitleNumberOfLines: number
  itemSubtitleNumberOfLines: number
  itemShape: ArtworkShape
  item: ColumnItem
}) {
  const progressBar =
    item.showProgressBar && itemShape !== "circle" ? (
      <SliderItemProgressBar contentLink={item.link} />
    ) : undefined

  return (
    <ArtworkListItem
      withoutBottomSpacing={columnIndex === column.length - 1}
      data={
        item?.title
          ? {
              isLoading: false,
              title: item.title,
              subtitle: item.subtitle,
              artwork: item.imageUrl,
              link: item.link,
              showLockIcon: item.showLockIcon,
              linkParams: item.linkParams,
            }
          : { isLoading: true }
      }
      titleNumberOfLines={itemTitleNumberOfLines}
      subtitleNumberOfLines={itemSubtitleNumberOfLines}
      artworkShape={itemShape}
      artworkDimensions={{
        width: item.width,
        height: item.artworkHeight,
      }}
      height={item.artworkHeight + item.textHeight}
      progressBar={progressBar}
    />
  )
}

function SliderColumn({
  column,
  index,
  itemTitleNumberOfLines,
  itemSubtitleNumberOfLines,
  itemShape,
}: {
  column: Column
  index: number
  itemTitleNumberOfLines: number
  itemSubtitleNumberOfLines: number
  itemShape: ArtworkShape
}) {
  const getKeyForColumn = useCallback((column: Column, index: number) => {
    const titles = column.map((item) => item?.title)
    return `${
      titles.length
        ? `column-${index}-of-${titles.join("-")}`
        : `placeholder-column-${index}`
    }`
  }, [])
  return (
    <View>
      {column.map((item, i) => {
        return (
          <SliderItem
            key={`${
              item.title ? `item-${item.title}` : "placeholder"
            }-${i}-in-${getKeyForColumn(column, index)}`}
            column={column}
            columnIndex={i}
            itemTitleNumberOfLines={itemTitleNumberOfLines}
            itemSubtitleNumberOfLines={itemSubtitleNumberOfLines}
            itemShape={itemShape}
            item={item}
          />
        )
      })}
    </View>
  )
}
